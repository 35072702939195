import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CUSTOM_EDIT } from "../../../Redux/constants/actionTypes";
import { experienceput } from "../../../Redux/actions/experience";
import { Box, Stack, Typography, Button, FormControlLabel, Checkbox } from '@mui/material/';
import TextField from '@mui/material/TextField';


export default function ClientMenuInputs() {
  const [showMenu, setShowMenu] = React.useState(false);
  const experienceState = useSelector((state) => state.experienceState);
  const customEditData = useSelector((state) => state.customEditState.customEditData);
  const dispatch = useDispatch();

  const [recipientDetails, setRecipientDetails] = React.useState({
    menuCheckbox: customEditData.menuCheckbox,
    menu1: customEditData.menu1,
    menu2: customEditData.menu2,
    menu3: customEditData.menu3,
    menu4: customEditData.menu4,
  });
/*
  const handle = () => {
    const userDate = JSON.parse(localStorage.getItem("profile"))?.result;
    console.log(userDate.idExperience)
    dispatch(
      experienceput({
        email: userDate.email,
        password: userDate.password,
        experience: customEditData.experience,
        firstNameRecipient: customEditData.firstName,
        lastNameRecipient: customEditData.lastName,
        tel: customEditData.tel,
        emailRecepient: customEditData.email,
        date: customEditData.date,
        idVoice: customEditData.idVoice || experienceState.experienceData.result.idVoice,
        textVoice: customEditData.textVoice,
        idExperience: userDate.idExperience,
        experiencePhoto: customEditData.experiencePhoto,
        menuCheckbox: customEditData.menuCheckbox,
        menu1: customEditData.menu1,
        menu2: customEditData.menu2,
        menu3: customEditData.menu3,
        menu4: customEditData.menu4,
        URLCheckbox: customEditData.URLCheckbox,
        URL1: customEditData.URL1,
        URL2: customEditData.URL2,
        URL3: customEditData.URL3,
        URL4: customEditData.URL4,
        RPM_url: customEditData.RPM_url || experienceState.experienceData.result.RPM_url,
        avatar: customEditData.avatar
      })
    );
  }

  */
  const handleEdit = (event) => {
    event.preventDefault();

    if (event.target.id === "menu1")
      setRecipientDetails((prevState) => {
        return { ...prevState, menu1: event.target.value };
      });

    if (event.target.id === "menu2")
      setRecipientDetails((prevState) => {
        return { ...prevState, menu2: event.target.value };
      });

    if (event.target.id === "menu3")
      setRecipientDetails((prevState) => {
        return { ...prevState, menu3: event.target.value };
      });

    if (event.target.id === "menu4")
      setRecipientDetails((prevState) => {
        return { ...prevState, menu4: event.target.value };
      });

  };


  React.useEffect(() => {
    dispatch({
      type: CUSTOM_EDIT, data: {
        ...customEditData,
        menuCheckbox: recipientDetails.menuCheckbox,
        menu1: recipientDetails.menu1,
        menu2: recipientDetails.menu2,
        menu3: recipientDetails.menu3,
        menu4: recipientDetails.menu4
      }
    });
  },
    [recipientDetails])

    React.useEffect(() => {
      setRecipientDetails((prevState) => {
        return { ...prevState, menuCheckbox: showMenu };
    })},
      [showMenu])
  



  React.useEffect(() => {

    //console.log(experienceState.experienceData?.result.menu1)

    if (experienceState.experienceData?.result.menu1)
      setRecipientDetails((prevState) => {
        return {
          ...prevState, 
          menu1: experienceState.experienceData?.result.menu1,
          menu2: experienceState.experienceData?.result.menu2,
          menu3: experienceState.experienceData?.result.menu3,
          menu4: experienceState.experienceData?.result.menu4
        }
      })
  }, [experienceState
  ])
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Menu (Input text for menu below)
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={showMenu}
            onChange={() => setShowMenu(!showMenu)}
          />
        }
        label="Show Menu"
      />
      {showMenu && (
        <Box sx={{ mt: 2 }}>
          <Stack spacing={2} direction="column">
            <TextField
              required
              id="menu1"
              label="Menu 1"
              value={customEditData.menu1}
              fullWidth
              onChange={handleEdit}
            />
            <TextField
              required
              id="menu2"
              label="Menu 2"
              value={recipientDetails.menu2}
              fullWidth
              onChange={handleEdit}
            />
            <TextField
              required
              id="menu3"
              label="Menu 3"
              value={recipientDetails.menu3}
              fullWidth
              onChange={handleEdit}
            />
            <TextField
              required
              id="menu4"
              label="Menu 4"
              value={recipientDetails.menu4}
              fullWidth
              onChange={handleEdit}
            />
            {/* <Button variant="contained" onClick={handle}>Generate</Button> 
            <Typography variant="subtitle2"> This will generate your Brand Buddy experience. Please check and make sure text, grammar and menu links are correct.</Typography> */}
          </Stack>
        </Box>
      )}
    </>
  );
}