import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CUSTOM_EDIT } from "../../../Redux/constants/actionTypes";
import { experienceput } from "../../../Redux/actions/experience";
import { Box, Stack, Typography, Button, FormControlLabel, Checkbox } from '@mui/material/';
import TextField from '@mui/material/TextField';


export default function ClientMenuLinks() {
  const [showMenu, setShowMenu] = React.useState(false);
  const experienceState = useSelector((state) => state.experienceState);
  const customEditData = useSelector((state) => state.customEditState.customEditData);
  const dispatch = useDispatch();

  const [recipientDetails, setRecipientDetails] = React.useState({
    URLCheckbox: customEditData.URLCheckbox,
    URL1: customEditData.URL1,
    URL2: customEditData.URL2,
    URL3: customEditData.URL3,
    URL4: customEditData.URL4,
  });


  const handleEdit = (event) => {
    event.preventDefault();

    if (event.target.id === "URL1")
      setRecipientDetails((prevState) => {
        return { ...prevState, URL1: event.target.value };
      });

    if (event.target.id === "URL2")
      setRecipientDetails((prevState) => {
        return { ...prevState, URL2: event.target.value };
      });

    if (event.target.id === "URL3")
      setRecipientDetails((prevState) => {
        return { ...prevState, URL3: event.target.value };
      });

    if (event.target.id === "URL4")
      setRecipientDetails((prevState) => {
        return { ...prevState, URL4: event.target.value };
      });


    dispatch({
      type: CUSTOM_EDIT, data: {
        ...customEditData,
        URLCheckbox: recipientDetails.URLCheckbox,
        URL1: recipientDetails.URL1,
        URL2: recipientDetails.URL2,
        URL3: recipientDetails.URL3,
        URL4: recipientDetails.URL4
      }
    });

  };

  React.useEffect(() => {
    dispatch({
      type: CUSTOM_EDIT, data: {
        ...customEditData,
        URLCheckbox: recipientDetails.URLCheckbox,
        URL1: recipientDetails.URL1,
        URL2: recipientDetails.URL2,
        URL3: recipientDetails.URL3,
        URL4: recipientDetails.URL4
      }
    });
  },
    [recipientDetails])


    React.useEffect(() => {
      setRecipientDetails((prevState) => {
        return { ...prevState, URLCheckbox: showMenu };
    })},
      [showMenu])
  

  React.useEffect(() => {



    if (experienceState.experienceData?.result)
      setRecipientDetails((prevState) => {
        return {
          ...prevState, URL1: experienceState.experienceData?.result.URL1,
          URL2: experienceState.experienceData?.result.URL2,
          URL3: experienceState.experienceData?.result.URL3,
          URL4: experienceState.experienceData?.result.URL4
        }
      })
  }, [experienceState
  ])

  return (
    <>
      <Typography variant="h6" gutterBottom>Menu Links (Input text for menu links below)</Typography>
      <FormControlLabel
      control={
        <Checkbox
          checked={showMenu}
          onChange={() => setShowMenu(!showMenu)}
        />
      }
      label="Show Menu Links"
    />
     {showMenu && (
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2} direction="column">

          <TextField
            required
            id="URL1"
            label="URL 1"
            value={recipientDetails.URL1}
            fullWidth
            onChange={handleEdit}
          />
          <TextField
            required
            id="URL2"
            label="URL 2"
            value={recipientDetails.URL2}
            fullWidth
            onChange={handleEdit}
          />
          <TextField
            required
            id="URL3"
            label="URL 3"
            value={recipientDetails.URL3}
            fullWidth
            onChange={handleEdit}
          />
          <TextField
            required
            id="URL4"
            label="URL 4"
            value={recipientDetails.URL4}
            fullWidth
            onChange={handleEdit}
          />

        </Stack>
      </Box>)}
    </>
  );
}