import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CUSTOM_EDIT } from "../../../Redux/constants/actionTypes";
import { experienceput } from "../../../Redux/actions/experience";
import { BUTTON_GENERATE_CLICKED } from '../../../Redux/actions';
import { Box, Stack, Typography, Button, FormControlLabel, Checkbox } from '@mui/material/';
import TextField from '@mui/material/TextField';


export default function Generate() {

  const experienceState = useSelector((state) => state.experienceState);

  const buttonGenerateClickedActionState = useSelector((state) => state.buttonGenerateClickedActionState);
  const customEditData = useSelector((state) => state.customEditState.customEditData);
  const dispatch = useDispatch();


  const handle = () => {
    const userDate = JSON.parse(localStorage.getItem("profile"))?.result;
    console.log(userDate.idExperience)
    dispatch({type: BUTTON_GENERATE_CLICKED, payload: true})
    dispatch(
      experienceput({
        email: userDate.email,
        password: userDate.password,
        experience: customEditData.experience,
        firstNameRecipient: customEditData.firstName,
        lastNameRecipient: customEditData.lastName,
        tel: customEditData.tel,
        emailRecepient: customEditData.email,
        date: customEditData.date,
        idVoice: customEditData.idVoice || experienceState.experienceData.result.idVoice,
        textVoice: customEditData.textVoice,
        idExperience: userDate.idExperience,
        experiencePhoto: customEditData.experiencePhoto,
        menuCheckbox: customEditData.menuCheckbox,
        menu1: customEditData.menu1,
        menu2: customEditData.menu2,
        menu3: customEditData.menu3,
        menu4: customEditData.menu4,
        URLCheckbox: customEditData.URLCheckbox,
        URL1: customEditData.URL1,
        URL2: customEditData.URL2,
        URL3: customEditData.URL3,
        URL4: customEditData.URL4,
        RPM_url: customEditData.RPM_url || experienceState.experienceData.result.RPM_url,
        avatar: customEditData.avatar
      })
    );
  }

  return (   
  
      <Box sx={{ mt: 2 }}>
        <Stack spacing={2} direction="column">
          <Button variant="contained" onClick={handle}>Generate</Button>
        </Stack>
</Box>
  );
}