import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import QRCode from "qrcode.react";
import { Box, Container, Typography, Button, Link } from "@mui/material";

const QrCodeComponent = () => {
    const menuUrlProp = useSelector((state) => state.voiceUrl);
    const experience = useSelector((state) => state.experienceState);

    const [idExp, setIdExp] = useState("");
    const [salesDeckUrl, setSalesDeckUrl] = useState("test");

    useEffect(() => {
        if (experience && experience.experienceData?.result?.idExperience) {
            setIdExp(experience.experienceData.result.idExperience);
        }
    }, [experience]);

    const downloadQRCode = () => {
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${salesDeckUrl}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Container sx={{ alignItems: "center", textAlign: "center" }}>
                <QRCode
                    id="qr-gen"
                    value={`https://bbar.mobi/exp?id=${idExp}`}
                    level={"H"}
                    includeMargin={true}
                />
            </Container>
            <Typography variant="body2" gutterBottom>
                <Link href={`https://bbar.mobi/exp?id=${idExp}`} target="_blank">
                    {`https://bbar.mobi/exp?id=${idExp}`}
                </Link>
            </Typography>
            <Button variant="contained" onClick={downloadQRCode} fullWidth>
                Download
            </Button>
        </Box>
    );
};

export default QrCodeComponent;
