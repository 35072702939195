import React, { useState, useEffect } from "react";
import { Route, useNavigate, useLocation, } from "react-router-dom";
import { styled, createTheme, ThemeProvider, } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import * as actionType from "../../Redux/constants/actionTypes";
import decode from "jwt-decode";

import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import { Card, CardMedia, } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

import useStyles from "./styles";

import Logo from '../../assets/BrandBuddy_Logo.png'

import TextToSpeechComponent from './1_TextToSpeech/TextToSpeechComponent';
import ClientMenuInputs from './2_MenuInputs/ClientMenuInputs';
import ClientMenuLinks from './3_MenuLinks/ClientMenuLinks';
import QrCodeComponent from './3_MenuLinks/QrCodeComponent';
import Generate from "./5_Generate/Generate";


import DragDropFileUpload from './4_Preview/DragDropFileUpload';
import AvatarCreatorApp from './4_Preview/AvatarCreator';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://brandbuddyar.com/">
        https://brandbuddyar.com/
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


function htmlComponent() {
  return <html><head>
    <script src='https://aframe.io/releases/1.4.0/aframe.min.js'></script>
    <script src='//cdn.8thwall.com/web/aframe/aframe-extras-6.1.1.min.js'></script></head>  <body>
      <a-scene><a-assets>
        <a-asset-item id='canModel' src='./models/Augie_NewBot_Male.glb' preload='auto'></a-asset-item></a-assets>

        <a-entity id='avatar' gltf-model='#canModel' position='0 0 -2' scale='0.1 0.1 0.1' animation-mixer='clip: BoyBot_clip; loop: repeat'>
        </a-entity>

        <a-sky color='#ECECEC'></a-sky>
      </a-scene>
    </body></html>
}


const text1 = `

<head>
<meta charset='utf-8'>
<title>BBAR</title>
<meta name='description' content='Model Viewer (VR / AR) • A-Frame'>
<meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
<script src='https://aframe.io/releases/1.2.0/aframe.min.js'></script>
<script src='https://unpkg.com/aframe-extras@3.3.0/dist/aframe-extras.min.js'></script>

</head>

<body>
<a-scene id='aScene'>
  <a-assets>
    <a-asset-item id='canModel1' src='https://localhost:8080/sendfile?glbname=Augie_NewBot_Male.glb'
      response-type='arraybuffer' crossorigin='anonymous'></a-asset-item>

      <img id='imgTexture1' src='./textures/GHX2_3_Orange_Diffuse.png' />
      <img id='imgTexture2' src='./textures/GHX2_2_Black_Diffuse.png' />
  </a-assets>

  <a-entity id='avatar' gltf-model='#canModel1' position='0 0 -2' scale='0.1 0.1 0.1'
    animation-mixer='clip: BoyBot_clip; loop: repeat'>
  </a-entity>

  <a-sky color='#ECECEC'></a-sky>

</a-scene>
</body>

</html>

`
const text2 = `
<head>
<meta charset='utf-8'>
<title>BBAR</title>
<meta name='description' content='Model Viewer (VR / AR) • A-Frame'>
<meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
<script src='https://aframe.io/releases/1.2.0/aframe.min.js'></script>
<script src='https://unpkg.com/aframe-extras@3.3.0/dist/aframe-extras.min.js'></script>

</head>

<body>
<a-scene id='aScene'>
  <a-assets>
    <a-asset-item id='canModel1' src='https://brandbuddyar.com:8080/sendfile?glbname=Augie_NewBot_Male.glb'
      response-type='arraybuffer' crossorigin='anonymous'></a-asset-item>

      <img id='imgTexture1' src='./textures/GHX2_3_Orange_Diffuse.png' />
      <img id='imgTexture2' src='./textures/GHX2_2_Black_Diffuse.png' />
  </a-assets>

  <a-entity id='avatar' gltf-model='#canModel1' position='0 0 -2' scale='0.14 0.14 0.14'
    animation-mixer='clip: BoyBot_clip; loop: repeat'>
  </a-entity>

  <a-sky color='#ECECEC'></a-sky>

</a-scene>
</body>

</html>`

export default function ClientMenu() {
  let interval;
  const ref = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [rpm, setRpm] = React.useState(false);
  const experience = useSelector((state) => state.experienceState);
  const buttonGenerateClickedActionState = useSelector((state) => state.buttonGenerateClickedActionState);
  const classes = useStyles;
  //console.log(useStyles)
  const toggleDrawer = () => {
    //setOpen(!open);
    setOpen(false);
  };

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const logout = () => {
    clearInterval(interval);
    dispatch({ type: actionType.LOGOUT });

    history("/");

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);
      //console.log(decodedToken);

      interval = setInterval(() => {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          logout(); clearInterval(interval);

        };
        //console.log(interval)
      }, 1000);


    }

    setUser(JSON.parse(localStorage.getItem("profile")));
    //console.log("!!!!!!!!", user.subcription.planName)
  }, [location]);

  function handleClick(e) {
    console.log(e);
    history("/auth");
  }

  const handleFileUpload = (file) => {
    console.log(file);
    var mesh = ref.current.contentWindow.document.getElementById('avatar').getObject3D('mesh');

    const texture1 = new window.THREE.TextureLoader().load(file);
    if (mesh.getObjectByName('faceShape')) mesh.getObjectByName('faceShape').material.map = texture1
    if (mesh.getObjectByName('Wolf3D_Outfit_Top')) mesh.getObjectByName('Wolf3D_Outfit_Top').material.map = texture1

  };

  const changeAvatar = (cb) => {
    console.log(cb)
    if (cb === 'RPM') setRpm(true); else setRpm(false)
  }
  React.useEffect(() => {
    //ref.current.contentWindow.document.querySelector('a-scene').addEventListener('loaded', function () { console.log('Ready') })

    const LoopMode = {
      once: window.THREE.LoopOnce,
      repeat: window.THREE.LoopRepeat,
      pingpong: window.THREE.LoopPingPong,
    }
    function wildcardToRegExp(s) {
      return new RegExp(`^${s.split(/\*+/).map(regExpEscape).join('.*')}$`)
    }
    function regExpEscape(s) {
      return s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    }


    /*
        ref.current.onload = function () {
          console.log(ref.current.contentWindow.document.querySelector('a-scene'))
          //console.log(ref.current.contentWindow.document.getElementById('canModel').el.getObject3D('mesh')) 
    
          ref.current.contentWindow.document.getElementById('avatar').addEventListener('model-loaded', (e) => {
            var mesh = ref.current.contentWindow.document.getElementById('avatar').getObject3D('mesh').getObjectByName('faceShape');
            const customImg1 = ref.current.contentWindow.document.getElementById("imgTexture1").src;
            const texture1 = new window.THREE.TextureLoader().load(customImg1);
            setTimeout(() => {
              //mesh.material.map = texture1
              mesh.material.map.flipY = true;
              //mesh.material.roughness = 0.0
              //mesh.material.metalness = 0.0
              mesh.material.needsUpdate = false
    
              console.log(mesh.material)
            }, 1000);
    
          })
    
    
        }
    */

  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" color="inherit" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Card sx={{
              display: 'flex',
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              m: 1
            }}>
              <CardMedia
                component="img"
                sx={{ display: 'flex', flexDirection: 'column', width: 30, }}
                image={Logo}
                alt="Live from space album cover"
              />
            </Card>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Brand Buddy AR Client Portal
            </Typography>
            <Typography
              component="h2"
              variant="h6"
              color="#8b8b8b"
              noWrap
            >
              {/*`Plan: ${user.subcription?.planName || 'Сhoose plan'}`*/}
            </Typography>
            <IconButton color="inherit" onClick={logout}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {/*
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/*secondaryListItems/}
          </List>
        </Drawer>
      */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextToSpeechComponent key={Math.random() + ""} refToAscene={ref} changeAvatar={changeAvatar} />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ClientMenuInputs />

                  <ClientMenuLinks />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >

                  <Typography
                    component="h3"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{
                      flexGrow: 1,
                      fontSize: '14px',
                      p: 2,
                      whiteSpace: 'normal', // Разрешает перенос строк
                      wordBreak: 'break-word' // Переносит длинные слова
                    }}
                  >
                    This will generate your Brand Buddy experience. Please check and make sure text, grammar and menu links are correct.
                  </Typography>
                  <Generate />
                  {buttonGenerateClickedActionState.message ? <QrCodeComponent /> : null}
                </Paper>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12} md={12} lg={12}>
              {!rpm ? <>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 500,
                  }}
                >
                  <iframe ref={ref} style={{
                    position: "flex",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: "100%",
                    height: "100%"
                  }} srcDoc={process.env.NODE_ENV === "development" ? text1 : text2
                  }
                    src="about:blank"></iframe>

                  {/*
                <TermsAndConditionsPage />
                */}
                </Paper>
                {/*<DragDropFileUpload onFileUpload={handleFileUpload} />*/}
              </> : null}
              {rpm ? <AvatarCreatorApp refToAscene={ref} /> : null}
            </Grid>
            <Grid item xs={12} md={12} lg={12}></Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
