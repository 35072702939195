import { EXPERIENCE_PUT, EXPERIENCE_GET } from "../constants/actionTypes";
import * as api from "../../api/index.js";

export const experienceput = (formData, router) => async (dispatch) => {
  try {
    console.log(formData)
    const newFormData = new FormData();
    newFormData.append("email", formData.email);
    newFormData.append("password", formData.password);
    newFormData.append("RPM_url", formData.RPM_url);
    newFormData.append("menuCheckbox", formData.menuCheckbox);
    newFormData.append("menu1", formData.menu1);
    newFormData.append("menu2", formData.menu2);
    newFormData.append("menu3", formData.menu3);
    newFormData.append("menu4", formData.menu4);
    newFormData.append("URLCheckbox", formData.URLCheckbox);
    newFormData.append("URL1", formData.URL1);
    newFormData.append("URL2", formData.URL2);
    newFormData.append("URL3", formData.URL3);
    newFormData.append("URL4", formData.URL4);
    newFormData.append("date", formData.date);
    newFormData.append("emailRecepient", formData.emailRecepient);
    newFormData.append("experience", formData.experience);
    newFormData.append("firstNameRecipient", formData.firstNameRecipient);
    newFormData.append("idExperience", formData.idExperience);
    newFormData.append("idVoice", formData.idVoice);
    newFormData.append("lastNameRecipient", formData.lastNameRecipient);
    newFormData.append("tel", formData.tel);
    newFormData.append("textVoice", formData.textVoice);
    newFormData.append("avatar", formData.avatar)

    if (formData.experiencePhoto instanceof File) {
      console.log("instance of file")
      newFormData.append("experiencePhoto", formData.experiencePhoto);
    } else {
      console.log("not instance of")
    }
    // console.log(formData)
    //console.log(formData.idExperience)


    //const { data } = await api.experiencePut(formData);
    const { data } = await api.experiencePut(newFormData);

    dispatch({ type: EXPERIENCE_PUT, data });
    console.log(data)
    //router("/");
  } catch (error) {
    console.log(error);
  }
};

export const experienceget = (formData, router) => async (dispatch) => {
  try {
    //console.log(formData)
    const { data } = await api.experienceGet(formData);
    //console.log(data)
    dispatch({ type: EXPERIENCE_PUT, data });
    
    //router("/");
  } catch (error) {
    console.log(error);
  }
};
