import { BUTTON_GENERATE_CLICKED } from "../actions";

const initialState = {
    message: "",
};

const buttonGenerateClickedActionReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUTTON_GENERATE_CLICKED:
            console.log('BUTTON_GENERATE_CLICKED')
            return {
                ...state,
                message: action.payload, // Сохраняем новое сообщение
            };
        default:
            return state;
    }
};

export default buttonGenerateClickedActionReducer;